import React from 'react';
import { imageUrlFor } from '../lib/image-url';
import { buildImageObj } from '../lib/helpers';

export default function Hero({ hero, title }) {
  return (
    <div className={`hero ${title ? 'with-title' : ''}`}>
      <div className="hero-inner">
        {title ? <h1>{title}</h1> : ``}
        <img
          src={imageUrlFor(buildImageObj(hero))
            .width(900)
            .height(Math.floor((9 / 16) * 900))
            .fit('crop')
            .url()}
          alt="test"
        />
      </div>
    </div>
  );
}
