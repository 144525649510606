import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { serializers } from '@sanity/block-content-to-react/lib/targets/dom';
const PortableText = ({ blocks, id = 'block' }) => (
  <BlockContent
    blocks={blocks}
    serializers={serializers}
    className={id}
    renderContainerOnSingleChild
  />
);
export default PortableText;
